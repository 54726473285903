import { render, staticRenderFns } from "./PindahLokasi.vue?vue&type=template&id=321f4bf2&"
import script from "./PindahLokasi.vue?vue&type=script&lang=ts&"
export * from "./PindahLokasi.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/workspace/SPP/spp-erp-fe-staging/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('321f4bf2')) {
      api.createRecord('321f4bf2', component.options)
    } else {
      api.reload('321f4bf2', component.options)
    }
    module.hot.accept("./PindahLokasi.vue?vue&type=template&id=321f4bf2&", function () {
      api.rerender('321f4bf2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sales/berita-acara/ba-pindah-lokasi/PindahLokasi.vue"
export default component.exports